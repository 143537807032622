import { EventEmitter, Injectable } from '@angular/core';
import { SecurityService } from './security.service';
import { DbDaoService } from '../db/db-dao.service';
import { UuidService } from '../utils/uuid.utils';
import { SyncService } from './sync.service';
import { Invoice } from '../../classes/invoice.class';
import { Image } from '../../classes/image.class';
import { Customer } from '../../classes/customer.class';
import { InvoiceEntry, SpecialTaxing } from '../../classes/invoice-entry.class';
import { PRODUCT_TYPES } from '../../constants/product-types';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { DISCOUNTS_TYPES } from '../../constants/discounts-types.const';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';
import { InvoicesService } from './invoices.service';
import { InvoicePayment } from '../../classes/invoice-payment.class';
import { GastronomyTable } from '../../classes/gastronomy-table.class';
import { InvoiceDiscountModal } from '../../../pages/collection-view/invoice-discount-modal/invoice-discount-modal.component';
import { ModalService } from './modal.service';
import { LoadingService } from './loading.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Company } from '../../classes/company.class';
import { LogService } from './logger/log.service';
import { ReferenceEntity } from '../../classes/reference-entity';
import { UserNotificationService } from './user-notification/user-notification.service';
import * as moment from 'moment';
import { GiftCard } from '@pos-modules/assing-gift-card/gift-card';
import { PAYMENT_TRANSACTION } from '@pos-common/constants/payment-providers.const';
import { InvoicesApiService } from '../api/invoices-api.service';
import { InvoiceDetailsSwipeEvent } from '@pos-modules/invoice-details/interfaces/invoice-details.events';
import { SwipeDirection } from '@pos-common/constants/swipe-direction.enum';
import { MultipleGuestsService } from './multiple-guests/multiple-guests.service';
import { CustomerService } from './customers.service';
import { ignoreImageProps } from '@pos-common/services/system/logger';
import { EmployeesFacadeStore } from '@pos-stores/employees';
import { Employee } from '@pos-common/classes/employee.class';
import { PaymentMethod } from '@pos-common/classes/payment-method.class';

@Injectable()
export class CartService {
  private activeInvoice: Invoice = null;
  private separetedInvoice: Invoice = null;
  private invoiceForSeparation: Invoice = null;
  public activeInvoiceUpdated = new EventEmitter();
  public invoiceCustomerChanged: EventEmitter<any> = new EventEmitter();
  private invoiceEntryChangeEvent: EventEmitter<any> = new EventEmitter();
  private invoiceForSeparationChangeEvent: EventEmitter<any> = new EventEmitter();
  private invoicePaymentsChangeEvent: EventEmitter<any> = new EventEmitter();
  private invoiceTableChangeEvent: EventEmitter<any> = new EventEmitter();
  private activePaymentMethodChangeEvent: EventEmitter<any> = new EventEmitter();
  public tableChangedEvent: EventEmitter<any> = new EventEmitter();
  private logger = this.logService.createLogger('CartService');

  constructor(
    public UuidService: UuidService,
    public SecurityService: SecurityService,
    public DbDaoService: DbDaoService,
    public SyncService: SyncService,
    public AlertService: AlertService,
    public TranslateService: TranslateService,
    public InvoicesService: InvoicesService,
    public LoadingService: LoadingService,
    public modalService: ModalService,
    public userNotificationService: UserNotificationService,
    public employeeFacadeStore: EmployeesFacadeStore,
    private invoicesApiService: InvoicesApiService,
    private multipleGuestsService: MultipleGuestsService,
    private customerService: CustomerService,
    private logService: LogService,
  ) {}

  public set activePaymentMethodEvent(paymentMethod: PaymentMethod) {
    this.activePaymentMethodChangeEvent?.emit(paymentMethod);
  }

  public get activePaymentMethod(): Observable<PaymentMethod> {
    return this.activePaymentMethodChangeEvent?.asObservable();
  }

  public createInvoice(setAsActive: boolean = true): Invoice {
    const invoice = this.InvoicesService.createInvoice();
    if (setAsActive) {
      this.activeInvoice = invoice;
      this.activeInvoiceUpdated.emit(this.activeInvoice);
    }
    return invoice;
  }

  public setActiveInvoice(invoice: Invoice, saveRequired: boolean = false) {
    if (invoice) {
      this.activeInvoice = invoice;
      if (this.activeInvoice.customer) {
        this.DbDaoService.getDataByUUID(UPDATES_TYPES.Customer.type, this.activeInvoice.customer['uuid'])
          .then((data) => (data['data'] ? this.setCustomer(new Customer(data['data']), false) : this.setCustomer(null, false)))
          .catch(() => this.setCustomer(null, false));
      } else {
        this.recalculateEntriesPositions();
        this.calculateTotalCount(saveRequired);
      }
    }
  }

  public getActiveInvoice() {
    return this.activeInvoice;
  }

  public setSeparetedInvoice(invoice: Invoice) {
    this.separetedInvoice = invoice;
    if (invoice) {
      this.logger.info('Separeted invoice has been set', { invoiceUuid: invoice?.uuid });
    }
  }

  public getSeparetedInvoice(): Invoice {
    const { separetedInvoice } = this;
    if (separetedInvoice) {
      separetedInvoice.isSeparated = false;
    }
    return separetedInvoice;
  }

  public hasSeparetedInvoice(): boolean {
    if (this.separetedInvoice) {
      return this.separetedInvoice.invoiceEntries.length > 0;
    }
    return false;
  }

  public async setCustomerFromGuestToInvoiceCustomer(invoice: Invoice) {
    const invoiceEntryGuest = invoice.getGuestInvoiceEntries().find((_) => true);
    let customer: Customer = null;
    if (invoiceEntryGuest?.customerIdentifier) {
      customer = await this.customerService.getCustomerByUuid(invoiceEntryGuest.customerIdentifier);
    }
    this.setCustomerDataToInvoice(invoice, customer);
    this.InvoicesService.calculateInvoiceAmountAfterDiscount(invoice);
    return invoice;
  }

  public setInvoiceStoreAndEmployee(storeUuid: string, employeeUuid: string) {
    if (
      this.activeInvoice &&
      (!this.activeInvoice.store ||
        !this.activeInvoice.employee ||
        this.activeInvoice.store?.uuid !== storeUuid ||
        this.activeInvoice.employee?.uuid !== employeeUuid)
    ) {
      const store = new ReferenceEntity(storeUuid);
      this.activeInvoice.store = store;
      this.activeInvoice.employee = new ReferenceEntity(employeeUuid);
      this.activeInvoice.inventoryStore = store;
      this.logger.info('Active invoice store, employee, inventoryStore were set', { storeUuid, employeeUuid, inventoryStore: storeUuid });
      this.saveInvoice();
    }
  }

  public addEntry(data: any, type: string, quantity: number, saveRequired: boolean = true) {
    if (!this.activeInvoice) this.createInvoice();
    this.logger.debug('Start to add entry to invoice', { invoiceUuid: this.activeInvoice?.uuid });
    let currentCartEntryIndex;
    let currentEntry: InvoiceEntry = null;

    this.setEntryDiscount(data, data.discount, data.discountPercentage);
    InvoiceEntry.calculateEntryDiscountedPrice(data);
    data.guestNumber = data.guestNumber || this.multipleGuestsService.activeGuestNumber;

    currentCartEntryIndex = this.InvoicesService.getEntryByUuidAndCategory(PRODUCT_TYPES[type], data, this.activeInvoice?.invoiceEntries);

    if (currentCartEntryIndex.length === 0) {
      const limitInvoiceEntries = 255;
      if (this.activeInvoice?.invoiceEntries?.length > limitInvoiceEntries) {
        this.logger.info('Invoice has invoiceEntry limit', { invoiceUuid: this.activeInvoice?.uuid });
        this.userNotificationService.showMessage('globl_error_invoice_entries_limit');
        return;
      }
      this.addInvoiceEntryToActiveInvoice(data, PRODUCT_TYPES[type], quantity);
    } else {
      currentEntry = this.activeInvoice?.invoiceEntries?.[currentCartEntryIndex[0]];

      currentEntry.update(data);
      currentEntry.quantity += quantity;
      currentEntry.quantityForKitchenReceipt += quantity;

      this.setEntryDiscount(currentEntry, data.discount, data.discountPercentage);
      currentEntry && currentEntry.calculateDiscountedPrice();

      currentEntry.productVariant = {
        uuid: data.variant ? data.variant.uuid : data?.productVariant?.['uuid'],
      };
      currentEntry.localModificationDate = moment.utc().toISOString();
      this.logger.info('addEntry: currentEntry was updated with quantity in invoice', {
        currentEntryUuid: currentEntry?.uuid,
        quantity: currentEntry?.quantity,
        invoiceUuid: this.activeInvoice?.uuid,
      });
    }
    this.calculateTotalCount(saveRequired);

    // Remove if advanced TakeAway VAT rates functional needed
    if (this.SecurityService.getLoggedCompanyData()['isTakeAwayEnabled']) {
      let foundTakeAway = false;
      this.activeInvoice?.invoiceEntries?.forEach((entry) => {
        if (entry && entry.specialTaxing === SpecialTaxing.takeAway) foundTakeAway = true;
      });
      if (foundTakeAway) this.recalculateVatRates(SpecialTaxing.takeAway);
    }
  }

  public changeEntryQuantity(entry: InvoiceEntry, changeValue: number): void {
    const activeInvoiceEntry = this.getEntryFromActiveInvoice(entry?.uuid);
    const newEntry = activeInvoiceEntry || entry;
    if (this.isEmployeeForbiddenToEditItems(newEntry, entry?.price)) {
      this.userNotificationService.showMessage('employee_are_not_able_to_edit_items');
      return;
    }
    newEntry.update(entry);
    if (!this.isEmployeeAllowedToRemoveItems(entry.quantityForKitchenReceipt, changeValue)) {
      this.userNotificationService.showEmployeeDoesntAllowedPopup();
      return;
    }
    if (newEntry.type !== PRODUCT_TYPES.TIPS && this.isForbiddenToDeleteEntry(newEntry?.quantity, changeValue)) {
      this.userNotificationService.showInvoiceContainsPaymentAlert();
      return;
    }
    newEntry.quantity += changeValue;
    const quantityForKitchenReceipt = newEntry.quantityForKitchenReceipt + changeValue;
    newEntry.quantityForKitchenReceipt = quantityForKitchenReceipt < 0 ? 0 : quantityForKitchenReceipt;
    if (newEntry?.quantity === 0) {
      newEntry.deleted = true;
    }
    if (newEntry) {
      newEntry.calculateDiscountedPrice();
    }
    this.calculateTotalCount(false);
    this.checkActiveInvoiceIsPaid();
    this.saveInvoice();
    this.recalculateEntriesPositions();
  }

  public changeEntries(entries: InvoiceEntry[]) {
    entries.forEach((entry) => {
      const newEntry = this.getEntryFromActiveInvoice(entry?.uuid) || entry;
      newEntry.update(entry);
      if (entry.deleted) {
        newEntry.markAsDeleted();
      }
      if (newEntry) {
        newEntry.calculateDiscountedPrice();
      }
    });

    this.calculateTotalCount(false);
    this.checkActiveInvoiceIsPaid();
    this.recalculateEntriesPositions();
    this.saveInvoice();
  }

  private checkActiveInvoiceIsPaid() {
    if (this.activeInvoice) {
      const invoicePaymentsTotal = this.activeInvoice.getPaymentsTotal();
      if (this.activeInvoice.amount > 0 && invoicePaymentsTotal >= this.activeInvoice.amount) {
        this.activeInvoice.setInvoiceAsPaid();
        this.activeInvoice.invoiceId = this.InvoicesService.getInvoiceId();
      }
    }
  }

  private isEmployeeAllowedToRemoveItems(quantityForKitchenReceipt: number, changeValue: number): boolean {
    if (changeValue > 0) {
      return true;
    }
    const currentCompany: Company = this.SecurityService.getLoggedCompanyData();
    const currentEmployee: Employee = this.employeeFacadeStore.activeEmployeeSnapshot;
    if (currentCompany?.isRestaurantEnabled && !currentEmployee?.hasCancellationPermission) {
      const quantityForDeletion = quantityForKitchenReceipt - Math.abs(changeValue);
      return quantityForDeletion >= 0;
    }
    return true;
  }

  private isEmployeeForbiddenToEditItems(invoiceEntry: InvoiceEntry, changePrice: number): boolean {
    const { quantityForKitchenReceipt, quantity, price } = invoiceEntry;
    const currentCompany: Company = this.SecurityService.getLoggedCompanyData();
    const currentEmployee: Employee = this.employeeFacadeStore.activeEmployeeSnapshot;

    if (currentCompany?.isRestaurantEnabled && !currentEmployee?.hasCancellationPermission) {
      const quantityForPrinting = quantityForKitchenReceipt - Math.abs(quantity);
      return price !== changePrice && quantityForPrinting < 0;
    }
    return false;
  }

  private isForbiddenToDeleteEntry(quantity: number, changeValue: number) {
    const newQuantity = quantity + changeValue;
    const hasPayments = this.activeInvoice.isInvoiceHasPayments();
    const activeInvoiceEntries = this.activeInvoice.getActiveInvoiceEntries();
    const isLastActiveInvoiceEntry = activeInvoiceEntries.length === 1;
    return isLastActiveInvoiceEntry && hasPayments && newQuantity === 0;
  }

  // TODO: Investigate this method
  public getEntryFromActiveInvoice(entryUuid: string): InvoiceEntry {
    if (!this.activeInvoice) {
      return null;
    }
    const invoiceEntry = this.activeInvoice?.invoiceEntries?.find((invoiceEntry) => invoiceEntry?.uuid === entryUuid);
    return invoiceEntry || null;
  }

  private addInvoiceEntryToActiveInvoice(data: any, entryType: string, quantity: number) {
    // if product NOT exists in cart
    // push product to cart
    // set product quantity to 1 if it not already have quantity field

    let taxRate = 0;
    if (data.taxRate) {
      taxRate = data.taxRate;
    } else if (data.variant) {
      taxRate = data.variant.vatRate.value;
    }
    const entryToAdd = new InvoiceEntry({
      type: entryType,
      name: data?.name,
      shortName: data.shortName,
      image: data.image ? new Image(data.image) : null,
      note: data.note,
      quantity,
      quantityForKitchenReceipt: quantity || 1,
      price: data?.price,
      wasPrice: data.wasPrice,
      taxRate,
      position: this.activeInvoice?.invoiceEntries?.length || 0,
      discountedPrice: data.discountedPrice,
      discount: data.discount,
      discountPercentage: data.discountPercentage,
      bgColor: data.bgColor,
      guestNumber: data.guestNumber,
      employee: {
        uuid: this.employeeFacadeStore.activeEmployeeSnapshot?.uuid,
      },
      uuid: this.UuidService.generate(),
    });
    entryToAdd.setLocalCreationDate();

    entryToAdd.store = {
      uuid: this.SecurityService.getActiveStore()?.uuid,
    };

    const productTypes = [PRODUCT_TYPES.PRODUCT, PRODUCT_TYPES.GIFT_CARD, PRODUCT_TYPES.SERVICE];
    if (productTypes.includes(entryType)) {
      entryToAdd.productVariant = {
        uuid: data.variant ? data.variant.uuid : data?.productVariant?.['uuid'],
      };

      if (data.productCategory) {
        entryToAdd.productCategory = {
          uuid: data.productCategory,
        };
      } else {
        entryToAdd.productCategory = null;
      }

      entryToAdd.inventoryStore = {
        uuid: this.SecurityService.getActiveStore()?.uuid,
      };

      entryToAdd.giftCardPhysicalCode = data.giftCardPhysicalCode;
    }

    this.logger.info('addInvoiceEntryToActiveInvoice: invoiceEntry was added with quantity to invoice', {
      invoiceEntryUuid: entryToAdd?.uuid,
      quantity: entryToAdd?.quantity,
      invoiceUuid: this.activeInvoice.uuid,
    });
    this.activeInvoice?.invoiceEntries?.push(entryToAdd);
  }

  public getEntryImage(product, variant): Image {
    if (variant.images.length > 0 && variant.images[0].document) {
      return new Image(variant.images[0].document);
    } else if (product.images.length > 0 && product.images[0].image) {
      return new Image(product.images[0].image);
    } else {
      return null;
    }
  }

  public setEntryDiscount(entry, discount, discountPercentage) {
    if (discount) {
      entry.discount = discount;
      entry.discountPercentage = 0;
    } else if (discountPercentage) {
      entry.discount = 0;
      entry.discountPercentage = discountPercentage;
    } else {
      entry.discount = 0;
      entry.discountPercentage = 0;
    }
  }

  public recalculateEntriesPositions() {
    let position = 0;
    const invoiceEntries = this.activeInvoice?.invoiceEntries?.sort((a, b) => (a.isGuest === b.isGuest ? 0 : a.isGuest ? -1 : 1))
      .sort((a, b) => a.guestNumber - b.guestNumber);

    for (let i = 0; i < invoiceEntries.length; i++) {
      const current = invoiceEntries[i];
      if (current.deleted) {
        current.position = -1;
      } else {
        current.position = position;
        position++;
      }
    }
  }

  public calculateTotalCount(saveRequired: boolean = true) {
    this.activeInvoice && this.InvoicesService.calculateInvoiceAmountAfterDiscount(this.activeInvoice);

    if (saveRequired) {
      this.saveInvoice();
    } else {
      this.activeInvoiceUpdated.emit(this.activeInvoice);
    }
  }

  public getCustomer() {
    return this.activeInvoice?.customer ?? null;
  }

  public setCustomer(customerData: Customer, saveRequired: boolean = true) {
    if (!this.activeInvoice) this.createInvoice();
    if (this.activeInvoice.isPaid && !customerData) return;
    this.activeInvoice = this.setCustomerDataToInvoice(this.activeInvoice, customerData);
    if (this.activeInvoice) this.calculateTotalCount(saveRequired);
    this.invoiceCustomerChanged.emit(customerData);
  }

  public getActiveTableName() {
    return this.activeInvoice?.gastronomyTableName ?? null;
  }

  public getActiveTableUuid() {
    return this.activeInvoice?.gastronomyTable?.uuid ?? null;
  }

  public getInvoiceTableChangeEvent() {
    return this.invoiceTableChangeEvent;
  }

  public setTable(table: GastronomyTable) {
    if (!this.activeInvoice) this.createInvoice();
    this.activeInvoice.gastronomyTable = { uuid: table?.uuid };
    this.activeInvoice.gastronomyTableName = table?.name;
    this.invoiceTableChangeEvent.emit(this.activeInvoice);
    this.saveInvoice();
    this.calculateGastronomyTableInvoices(this.activeInvoice).catch((err) =>
      this.logger.error(err, 'setTable:calculateGastronomyTableInvoices')
    );
  }

  public setCustomerDataToInvoice(invoice: Invoice, customerData: Customer): Invoice {
    if (customerData) {
      invoice.customer = {
        uuid: customerData?.uuid,
        dataToShowInList: customerData.dataToShowInList || this.TranslateService.instant('customers_unknown'),
        deleted: customerData?.deleted
      };
      invoice.billingAddress = customerData.address;
      if (invoice.isWebshop || invoice.isSelfOrder) {
        return invoice;
      }
      if (!invoice.isPaid) {
        if (customerData.discount) {
          invoice.customerDiscount = customerData.discount;
          invoice.customerDiscountPercentage = 0;
        } else if (customerData.discountPercentage) {
          invoice.customerDiscount = 0;
          invoice.customerDiscountPercentage = customerData.discountPercentage;
        } else {
          invoice.customerDiscount = 0;
          invoice.customerDiscountPercentage = 0;
        }
      }
    } else if (invoice) {
      invoice.unlinkCustomerFromInvoice();
    }
    return invoice;
  }

  public setActiveInvoiceId(saveInvoice: boolean = true) {
    if (!this.activeInvoice) {
      this.createInvoice();
      saveInvoice = true;
    }
    if (this.activeInvoice && !this.activeInvoice.invoiceId) {
      this.activeInvoice.invoiceId = this.InvoicesService.getInvoiceId();
      this.logger.info('Invoice id was set to active invoice', {
        invoiceId: this.activeInvoice.invoiceId,
        invoiceUuid: this.activeInvoice?.uuid,
      });
      saveInvoice && this.saveInvoice();
    }
  }

  addPaymentToInvoice(activeInvoice: Invoice, method: string, amount: any, amountGiven: any, giftCard?: GiftCard): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.logger.debug('Start to add payment to invoice', { method, invoiceUuid: activeInvoice.uuid });
      activeInvoice = new Invoice(activeInvoice);

      let addPaymentPromise: Promise<Invoice> = null;
      if (activeInvoice.hasGiftCardsEntries() || giftCard) {
        addPaymentPromise = this.addGiftCardPayment(activeInvoice, method, amount, amountGiven, giftCard);
      } else {
        addPaymentPromise = this.addPayment(activeInvoice, method, amount, amountGiven);
      }

      addPaymentPromise
        .then((invoice) => {
          const separatedInvoice = this.getSeparetedInvoice();
          if (separatedInvoice) {
            this.logger.info('addPaymentToInvoice: Save separeted invoice', { invoiceUuid: separatedInvoice.uuid });
            this.InvoicesService.saveInvoice(separatedInvoice);
          }
          this.logger.info('The payment was added to invoice', { method, invoiceUuid: invoice.uuid });
          resolve(invoice);
        })
        .catch((err) => {
          this.logger.error(err, 'addPaymentToInvoice:addPaymentPromise');
          reject(err);
        });
    });
  }

  private addGiftCardPayment(activeInvoice: Invoice, method: string, amount: any, amountGiven: any, giftCard: GiftCard): Promise<Invoice> {
    let isPaidGiftCardInvoice = false;
    return this.InvoicesService.addPaymentToInvoice(
      activeInvoice,
      method,
      amount,
      amountGiven,
      PAYMENT_TRANSACTION.PAYMENT_PURCHASE,
      giftCard
    )
      .then((invoice: Invoice) => {
        if (invoice.isPaid) {
          isPaidGiftCardInvoice = true;
          invoice.invoiceId = this.InvoicesService.getInvoiceId();
          this.updateDiscountByTotalDiscountAmount(invoice);
        }
        return this.updateInvoiceOnServer(invoice);
      })
      .then((invoiceWithPayment: Invoice) => {
        this.activeInvoice = invoiceWithPayment;
        this.invoicePaymentsChangeEvent.emit(this.activeInvoice.invoicePayments);
        this.logger.info('Payment method has been added to invoice with amount and amountGiven', {
          method,
          invoiceUuid: invoiceWithPayment.uuid,
          amount,
          amountGiven,
        });
        if (this.activeInvoice.isPaid) {
          this.logger.info('Active invoice has been paid', { invoiceUuid: this.activeInvoice.uuid });
          this.saveInvoice();
          this.calculateGastronomyTableInvoices(this.activeInvoice).catch((err) =>
            this.logger.error(err, 'addPaymentToInvoice:calculateGastronomyTableInvoices')
          );
        }
        return this.activeInvoice;
      })
      .catch((err) => {
        if (isPaidGiftCardInvoice) {
          this.logger.info('The paid invoice with gift card payment failed to sync, the invoice number should be decreased', {
            invoiceUuid: this.activeInvoice.uuid,
          });
          this.SecurityService.decreaseNextInvoiceNumber();
          this.userNotificationService.showGiftCardProcessingErrorMessage();
        }
        throw err;
      });
  }

  private addPayment(activeInvoice: Invoice, method: string, amount: any, amountGiven: any): Promise<Invoice> {
    return this.InvoicesService.addPaymentToInvoice(activeInvoice, method, amount, amountGiven, PAYMENT_TRANSACTION.PAYMENT_PURCHASE).then(
      (invoiceWithPayment: Invoice) => {
        this.activeInvoice = invoiceWithPayment;
        this.invoicePaymentsChangeEvent.emit(this.activeInvoice.invoicePayments);
        this.logger.info('Payment with amount and amountGiven has been added to invoice', {
          method,
          amount,
          amountGiven,
          invoiceUuid: invoiceWithPayment.uuid,
        });
        if (this.activeInvoice.isPaid) {
          this.logger.info('Active invoice has been paid', { invoiceUuid: this.activeInvoice.uuid });
          this.updateDiscountByTotalDiscountAmount(this.activeInvoice);
          this.setActiveInvoiceId(false);
          this.calculateGastronomyTableInvoices(this.activeInvoice).catch((err) =>
            this.logger.error(err, 'addPaymentToInvoice:calculateGastronomyTableInvoices')
          );
        } else {
          this.saveInvoice();
        }
        return this.activeInvoice;
      }
    );
  }

  private updateDiscountByTotalDiscountAmount(activeInvoice: Invoice) {
    this.InvoicesService.calculateInvoiceAmountAfterDiscount(activeInvoice);
    const { discount, totalInvoiceDiscountAmount, customerDiscount, totalCustomerDiscountAmount } = activeInvoice;
    const shouldUpdateInvoiceDiscount = activeInvoice.hasDiscount() && discount > totalInvoiceDiscountAmount;
    if (shouldUpdateInvoiceDiscount) {
      activeInvoice.discount = totalInvoiceDiscountAmount;
      this.InvoicesService.calculateInvoiceAmountAfterDiscount(activeInvoice);
    }

    const shoudlUpdateCustomerDiscount = activeInvoice.hasCustomerDiscount() && customerDiscount > totalCustomerDiscountAmount;
    if (shoudlUpdateCustomerDiscount) {
      activeInvoice.customerDiscount = totalCustomerDiscountAmount;
      this.InvoicesService.calculateInvoiceAmountAfterDiscount(activeInvoice);
    }
  }

  private updateInvoiceOnServer(invoice: Invoice, counter: number = 0): Promise<Invoice> {
    const limit = 3;
    counter++;
    return new Promise<Invoice>((resolve, reject) => {
      setTimeout(() => {
        this.invoicesApiService
          .updateInvoice(invoice)
          .then(resolve)
          .catch(() => {
            if (counter > limit) {
              reject();
              return;
            }
            this.updateInvoiceOnServer(invoice, counter).then(resolve).catch(reject);
          });
      }, counter * 500);
    });
  }

  public setPrintedStatusToInvoice(invoice: Invoice): void {
    this.logger.debug('Start to set printer status to invoice', { invoiceUuid: invoice.uuid });
    if (this.activeInvoice.uuid === invoice.uuid) {
      this.activeInvoice.isPrinted = true;
      this.setActiveInvoice(this.activeInvoice);
      this.saveInvoice();
    } else {
      invoice.isPrinted = true;
      this.InvoicesService.saveInvoice(invoice).catch((err) => this.logger.error(err, 'setPrintedStatusToInvoice:saveInvoice'));
    }
    this.logger.info('isPrinted status was added to invoice', { invoiceUuid: invoice.uuid });
  }

  public setShoppingStatusToInvoice(invoice: Invoice, shoppingStatus: string) {
    this.logger.debug('The invoice set shoping status', { invoiceUuid: invoice.uuid, shoppingStatus });
    if (this.activeInvoice.uuid === invoice.uuid) {
      this.activeInvoice.fulfillmentState = shoppingStatus;
      this.setActiveInvoice(this.activeInvoice);
      this.saveInvoice();
      return;
    }
    invoice.fulfillmentState = shoppingStatus;
    this.InvoicesService.saveInvoice(invoice).catch((err) => this.logger.error(err, 'setPrintedStatusToInvoice:saveInvoice'));
    this.logger.info('The shoping status was added to invoice', { shoppingStatus, invoiceUuid: invoice.uuid });
  }

  public paymentChanged: Subject<any> = new BehaviorSubject<any>(null);
  public paymentsMethodWatcher = () => this.paymentChanged.asObservable();
  public paymentsMethodSetter = (payment) => this.paymentChanged.next(payment);

  public setPaymentsToActiveInvoice(payments: Array<InvoicePayment>) {
    this.activeInvoice.invoicePayments = payments;
    this.invoicePaymentsChangeEvent.emit(this.activeInvoice.invoicePayments);
    this.saveInvoice();
  }

  public saveInvoice() {
    if (
      (this.activeInvoice?.invoiceEntries?.length === 0 || this.activeInvoice.totalProductsCount === 0) &&
      (this.activeInvoice.discount || this.activeInvoice.discountPercentage)
    ) {
      this.removeInvoiceDiscount();
      return false;
    }

    this.logger.debug('saveInvoice', { activeInvoice: ignoreImageProps(this.activeInvoice) });
    // TODO check for inconsistent state
    if (this.isActiveInvoiceDummy()) {
      let invoiceToDelete = this.activeInvoice;
      this.InvoicesService.deleteInvoice(invoiceToDelete)
        .then(() => {
          this.createInvoice();
        })
        .catch((err) => this.logger.error(err, 'saveInvoice:isActiveInvoiceDummy:deleteInvoice'));
      return false;
    }

    this.InvoicesService.saveInvoice(this.activeInvoice)
      .then((data) => {
        this.activeInvoiceUpdated.emit(this.activeInvoice);
      })
      .catch((err) => this.logger.error(err, 'saveInvoice:InvoicesService:saveInvoice'));
  }

  public setDataAfterSuccessPay() {
    const separatedInvoice = this.getSeparetedInvoice();
    if (separatedInvoice) {
      this.setActiveInvoice(separatedInvoice);
      this.saveInvoice();
      this.setSeparetedInvoice(null);
    } else {
      this.createInvoice();
    }
  }

  public cleanInvoiceEntriesKitchenQuantity(guestNumber: number): void {
    this.activeInvoice.cleanInvoiceEntriesKitchenQuantity(guestNumber);
    this.setActiveInvoice(this.activeInvoice);
    this.saveInvoice();
  }

  public cleanInvoiceEntriesKitchenQuantityForSelfOrderPaid(guestNumber: number, invoice: Invoice): void {
    invoice.cleanInvoiceEntriesKitchenQuantity(guestNumber);
    this.InvoicesService
      .saveInvoice(invoice)
      .catch((err) => this.logger.error(err, 'saveInvoice:InvoicesService:saveInvoice'));
  }

  public setNewTableToInvoice(table, invoice: Invoice) {
    if (table) {
      invoice.gastronomyTable ? (invoice.gastronomyTable.uuid = table.uuid) : (invoice.gastronomyTable = { uuid: table.uuid });
      invoice.gastronomyTableName = table.name;
    } else {
      invoice.gastronomyTable = null;
      invoice.gastronomyTableName = null;
    }
    this.InvoicesService.saveInvoice(invoice, false)
      .then(() => {
        invoice.uuid === this.activeInvoice.uuid && this.setActiveInvoice(invoice);
        this.calculateGastronomyTableInvoices(invoice)
          .then(() => {
            if (table) {
              this.tableChangedEvent.emit({ table: table.uuid, invoice: invoice.uuid });
            } else {
              this.tableChangedEvent.emit({ table: null, invoice: invoice.uuid });
            }
          })
          .catch((err) => this.logger.error(err, 'setNewTableToInvoice:saveInvoice:calculateGastronomyTableInvoices'));
      })
      .catch((err) => this.logger.error(err, 'setNewTableToInvoice:saveInvoice'));
  }

  public emitInvoiceUpdate = (invoice) => this.activeInvoiceUpdated.emit(invoice);

  public isActiveInvoiceDummy(): boolean {
    const hasGuestInvoiceEntry = this.activeInvoice.hasGuestInvoiceEntry();
    if (
      (this.activeInvoice?.invoiceEntries?.length === 0 || this.activeInvoice.totalProductsCount === 0) &&
      !hasGuestInvoiceEntry &&
      !this.activeInvoice.invoiceId &&
      this.activeInvoice.isDraft &&
      !this.activeInvoice.customer &&
      !this.activeInvoice.gastronomyTable &&
      !this.activeInvoice.discountCode &&
      !this.activeInvoice.isInvoiceHasPayments()
    ) {
      return true;
    }

    return false;
  }

  public setInvoiceDiscount(discountAmount: number, discountType: string) {
    if (discountAmount === 0) {
      this.removeInvoiceDiscount();
      return false;
    }

    if (discountType === DISCOUNTS_TYPES.PERCENTAGE) {
      this.activeInvoice.discount = 0;
      this.activeInvoice.discountPercentage = discountAmount;
    } else {
      this.activeInvoice.discount = discountAmount;
      this.activeInvoice.discountPercentage = 0;
    }

    this.calculateTotalCount();
  }

  public removeInvoiceDiscount() {
    this.activeInvoice.discount = 0;
    this.activeInvoice.discountPercentage = 0;
    this.calculateTotalCount();
  }

  public calculateGastronomyTableInvoices(invoice: Invoice | any): Promise<boolean> {
    return new Promise((resolve) => {
      this.DbDaoService.getAllData(UPDATES_TYPES.GastronomyHall.type)
        .then((hallsData: any) => {
          let needToSaveDatabase = false;
          // if (!invoice.gastronomyTable || !invoice.gastronomyTable.uuid) {
          //   resolve(false);
          //   return;
          // }
          for (let hall of hallsData.data) {
            for (let table of hall.gastronomyTables) {
              //through whole list of tables
              if (invoice.gastronomyTable && table.uuid === invoice.gastronomyTable.uuid && !invoice.isPaid && !invoice.deleted) {
                //yes! we found table of our invoice
                let found = false;
                for (let invUuid of table.invoices) {
                  //searching for our invoice in table.invoices
                  if (invUuid.uuid === invoice.uuid) {
                    found = true; //yes! we found our invoice is already in list of invoices of table => everything is ok, synchronized
                  }
                }
                if (!found) {
                  //not found, so we add our invoice to invoices list of this table
                  table.invoices.push({ uuid: invoice.uuid });
                  this.DbDaoService.upsertDataToCollection(UPDATES_TYPES.GastronomyHall.type, [hall]).catch((err) =>
                    this.logger.error(err, 'calculateGastronomyTableInvoices:notfound:upsertDataToCollection')
                  );
                  needToSaveDatabase = true;
                }
              } else {
                // here we must remove our invoice from invoice list of table, if our invoice doesn't belong to this table anymore
                for (let i = 0; i < table.invoices.length; i++) {
                  let invUuid = table.invoices[i];
                  if (invoice.uuid === invUuid.uuid) {
                    //ok, this means we found our invoice, that belongs to some table, but uuid of this table is not uuid of table of our invoice, so we remove outdated info
                    table.invoices.splice(i, 1);
                    needToSaveDatabase = true;
                    this.DbDaoService.upsertDataToCollection(UPDATES_TYPES.GastronomyHall.type, [hall]).catch((err) =>
                      this.logger.error(err, 'calculateGastronomyTableInvoices:found:upsertDataToCollection')
                    );
                    break;
                  }
                }
              }
            }
          }
          resolve(needToSaveDatabase);
        })
        .catch((err) => this.logger.error(err, 'calculateGastronomyTableInvoices:getAllDataFromCollection'));
    });
  }

  public getInvoiceForSeparation(): Invoice {
    return this.invoiceForSeparation;
  }

  public setInvoiceForSeparation(invoice: Invoice, clean?: boolean) {
    this.invoiceForSeparation = invoice;
    if (clean) this.invoiceForSeparation = null;
    this.invoiceForSeparationChangeEvent.emit(invoice);
  }

  public getInvoiceEntryChangeEvent(): EventEmitter<any> {
    return this.invoiceEntryChangeEvent;
  }

  public getInvoiceForSeparationChangeEvent(): EventEmitter<any> {
    return this.invoiceForSeparationChangeEvent;
  }

  public getInvoicePaymentsChangeEvent(): EventEmitter<any> {
    return this.invoicePaymentsChangeEvent;
  }

  public async openInvoiceDiscountModal(activeInvoice: Invoice): Promise<any> {
    if (!activeInvoice || (activeInvoice && !activeInvoice.totalProductsCount)) return false;
    let invoiceDiscountData: any = {
      discount: activeInvoice.discount,
      discountPercentage: activeInvoice.discountPercentage,
    };
    const invoiceDiscountModal = await this.modalService.presentModal(InvoiceDiscountModal, { invoiceDiscountData: invoiceDiscountData });
    invoiceDiscountModal.onDidDismiss().then((data) => {
      if (data.data && data.data.closedByUser && data.data.discountType) {
        this.setInvoiceDiscount(data.data.discountDecimal, data.data.discountType);
      } else if (data.data && data.data.closedByUser && !data.data.discountType) {
        this.removeInvoiceDiscount();
      }
    });
    invoiceDiscountModal.present();
    return invoiceDiscountModal;
  }

  public recalculateVatRates(param) {
    let activeInvoice = this.activeInvoice;
    const finaliseConunter = (counter: number) => {
      if (counter === activeInvoice.invoiceEntries.length) {
        activeInvoice.totalAmountWithoutTaxes =
          activeInvoice.totalAmountWithoutDiscount - activeInvoice.invoiceEntries.reduce((acc, cur) => acc + cur['totalTaxAmount'], 0);
        activeInvoice.totalAmountWithoutTaxes = parseFloat(activeInvoice.totalAmountWithoutTaxes.toFixed(2));
        this.activeInvoice = activeInvoice;
        this.saveInvoice();
      }
    };

    if (param === SpecialTaxing.inHouse) {
      let counter = 0;
      activeInvoice.invoiceEntries.forEach((entry) => {
        entry.specialTaxing = SpecialTaxing.inHouse;
        if (entry.productVariant) {
          this.DbDaoService.getDataByUUID(UPDATES_TYPES.ProductVariant.type, entry.productVariant['uuid']).then((data) => {
            let rate = data.data.vatRate.value;
            if (rate) {
              entry.taxRate = rate;
              entry.totalTaxAmount = (entry.totalAmount / (1 + entry.taxRate)) * entry.taxRate;
            }
            counter++;
            finaliseConunter(counter);
          });
        } else {
          counter++;
          finaliseConunter(counter);
        }
      });
    }

    if (param === SpecialTaxing.takeAway) {
      let currentCompany: Company = this.SecurityService.getLoggedCompanyData();
      if (currentCompany.defaultTakeAwayCompanyVATRate) {
        this.DbDaoService.getDataByUUID(UPDATES_TYPES.VATRate.type, currentCompany.defaultTakeAwayCompanyVATRate['uuid'])
          .then((takeAwayDefaultVat) => {
            if (takeAwayDefaultVat && takeAwayDefaultVat.data && takeAwayDefaultVat.data.value) {
              let takeAwayVAT = takeAwayDefaultVat.data.value;
              let counter = 0;
              activeInvoice.invoiceEntries.forEach((entry) => {
                if (entry.productVariant) {
                  this.DbDaoService.getDataByUUID(UPDATES_TYPES.ProductVariant.type, entry.productVariant['uuid'])
                    .then((data) => {
                      if (data.data && data.data.isTakeAwayAllowed) {
                        entry.taxRate = takeAwayVAT;
                        entry.specialTaxing = SpecialTaxing.takeAway;
                        entry.totalTaxAmount = (entry.totalAmount / (1 + entry.taxRate)) * entry.taxRate;
                      }
                      counter++;
                      finaliseConunter(counter);
                    })
                    .catch((err) => this.logger.error(err, 'recalculateVatRates:getDataByUUID'));
                } else {
                  counter++;
                  finaliseConunter(counter);
                }
              });
            }
          })
          .catch((err) => this.logger.error(err, 'recalculateVatRates:takeAway:DbDaoService:getDataByUUID'));
      }
    }
  }

  invoiceEntrySwipe(swipeEvent: InvoiceDetailsSwipeEvent<InvoiceEntry>) {
    const { direction, entity } = swipeEvent;
    if (direction === SwipeDirection.BACK) {
      this.changeEntryQuantity(entity, -1);
    } else if (direction === SwipeDirection.FORWARD) {
      if (entity.type === PRODUCT_TYPES.GIFT_CARD || entity.type === PRODUCT_TYPES.TIPS) {
        return;
      }
      this.changeEntryQuantity(entity, 1);
    }
  }

  checkPartialPaymentInInvoice(message: string = 'invoice_has_partial_payment_error'): boolean {
    const hasPayments = this.activeInvoice.isInvoiceHasPayments();
    const isDisableAction = hasPayments && !this.activeInvoice.isPaid && !this.activeInvoice.isWebshop;
    if (isDisableAction) {
      this.userNotificationService.showMessage(message);
    }
    return isDisableAction;
  }

  public addNewGuest(saveRequired: boolean = true) {
    const newInvoiceEntryGuest = this.multipleGuestsService.createNewGuest();
    this.addEntry(newInvoiceEntryGuest, PRODUCT_TYPES.CATEGORY, 1, saveRequired);
  }

  public addCustomerToGuest(customer: Customer, guestNumber: number, invoice?: Invoice): Invoice {
    invoice = invoice || this.activeInvoice;
    const invoiceEntryGuest = invoice.getGuestInvoiceEntryByGuestNumber(guestNumber);
    if (!invoiceEntryGuest) {
      return;
    }
    this.multipleGuestsService.addCustomerToGuest(invoiceEntryGuest, customer, guestNumber);
    return invoice;
  }
}
